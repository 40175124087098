import React from 'react';
import StyledHeading from '../components/styledHeading';

const PrivacyPolicy = () => {
  return (
    <div className=" px-10 md:px-40 py-8 bg-zinc-950 overflow-hidden">
      <div className='mx-auto'>
        <StyledHeading bg_text="P & P" fg_text="Privacy Policy" />
      </div>
      
      <p className="mb-4">
        This Privacy Policy governs the manner in which Peryton Technologies LLP. collects, uses, maintains, and discloses information collected from users (each, a “User”) of the www.peryton.in website (“Site”). This privacy policy applies to the Site and all products and services offered by Peryton Technologies LLP.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Welcome</h2>
      
      <p className="mb-4">
        Thank you for visiting our store. Your privacy is important to us. To better protect your privacy, we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used at our site. Your privacy is critically important to us.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Introduction</h2>
      
      <p className="mb-4">
        www.peryton.in is an Internet-based portal, a company incorporated under the laws of India. Use of the Website is offered to you conditioned on acceptance without modification of all the terms, conditions, and notices contained in these Terms, as may be posted on the Website from time to time. www.peryton.in at its sole discretion reserves the right not to accept a User from registering on the Website without assigning any reason thereof.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Personal Identification Information</h2>
      
      <p className="mb-4">
        We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, phone number, credit card information. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site-related activities.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Non-Personal Identification Information</h2>
      
      <p className="mb-4">
        We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers utilized, and other similar information.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Cookies</h2>
      
      <p className="mb-4">
        A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences.
      </p>
      
      <p className="mb-4">
        We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
      </p>
      
      <p className="mb-4">
        Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">How We Use Collected Information</h2>
      
      <ul className="list-disc pl-8 mb-6">
        <li>To Improve Customer Service: Information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
        <li>To Personalize User Experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
        <li>To Improve Our Site: We may use the feedback you provide to improve our products and services.</li>
        <li>To Process Payments: We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li>
        <li>To Run Promotions, Contests, Surveys, or Other Site Features: To send Users information they agreed to receive about topics we think will be of interest to them.</li>
        <li>To Send Periodic Emails: We may use the email address to send User information and updates pertaining to their order.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Security</h2>
      
      <p className="mb-4">
        We employ reasonable measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction. Nevertheless, please be aware that no data transmission over the Internet or data storage system can be guaranteed to be completely secure.
      </p>
      
      <h2 className="text-4xl font-semibold text-white mb-4">Children’s Privacy</h2>
      
      <p className="mb-4">
        Our services are not intended for individuals under the age of 13. We do not knowingly collect personal data from individuals under 13. If you believe that a child under 13 has provided us with personal data, please contact us so we can delete the information.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
