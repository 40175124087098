import React from 'react'

const AdminLogin = () => {
  return (
    <div>
        
    </div>
  )
}

export default AdminLogin