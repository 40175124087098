import React from 'react';
import StyledHeading from '../components/styledHeading';

const TermsAndConditions = () => {
  return (
    <div className="px-10 md:px-40 py-8 bg-zinc-950 overflow-hidden">
      <div className="mx-auto">
        <StyledHeading bg_text="T & C" fg_text="Terms & Conditions" />
      </div>

      <p className="mb-4">
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and Peryton
        (“Company,” “we,” “us,” or “our”), concerning your access to and use of
        the Peryton website (peryton.in) as well as any other media form, media
        channel, mobile website, or mobile application related, linked, or
        otherwise connected thereto (collectively, the “Site”). You agree that
        by accessing the Site, you have read, understood, and agreed to be
        bound by all of these Terms of Use.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">Warning Notice</h2>
      <p className="mb-4">
        Operating multi-rotor and other model aircraft or vehicles involves
        inherent dangers. Spinning propellers can cause severe injury; never
        work on models with mounted propellers. Lithium Polymer (LiPo)
        batteries can be unstable if not used or charged correctly, potentially
        leading to combustion or fire. Our power systems, including motors,
        batteries, and electronic speed control (ESC) units, can draw
        significant current, sometimes over 30 amps, which can be dangerous.
        Always ensure electronic systems are de-energized before working on
        them to prevent electrical shock. Never short the electrical leads,
        reverse the leads, or place the leads on your body.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">No Warranty/Liability</h2>
      <p className="mb-4">
        Peryton provides no warranty of any kind for any equipment it sells or
        distributes. You assume all risks for any products purchased or
        received from Peryton. Peryton and its employees are not liable for any
        injuries to you or others, or for any damage or destruction of
        property. Additionally, Peryton is not liable for any consequential or
        inconsequential damages resulting from the use or application of any
        products provided by Peryton.
      </p>

      <p className="mb-4">
        We sell our equipment expressly without warranty or performance
        guarantees for any use or situation. It is the user's responsibility to
        ensure that the products safely meet their intended purpose.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">Published Product Details</h2>
      <p className="mb-4">
        Although we strive to provide accurate information on our website,
        Peryton is not liable for any issues resulting from incorrect product
        details published on our site due to the ever-evolving standards and
        specifications of the FPV world. Specifications and prices may change
        without notice and may not be reflected on the product page. For
        confirmation, contact Peryton customer service directly.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">Training Courses</h2>
      <p className="mb-4">
        The content provided in our training courses is for educational purposes
        only and does not guarantee any particular outcome or certification.
        We reserve the right to modify course content, schedules, or materials
        at any time without prior notice.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">License to Use Website</h2>
      <p className="mb-4">
        Unless otherwise stated, Peryton.in and/or its licensors own the
        intellectual property rights published on this website. You may view,
        download (for caching purposes only), and print pages from the website
        for your personal use, subject to the restrictions set out in these
        terms and conditions.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">Fraudulent Activity</h2>
      <p className="mb-4">
        In cases where there is foul play or fraudulence, Peryton reserves the
        right to conduct a thorough investigation involving all stakeholders.
        We are not responsible if the customer accepts a tampered package from
        the courier personnel. Claims of missing, damaged, or incorrect
        products will only be considered with proof of unboxing.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify, defend, and hold harmless us and our affiliates
        from any claim due to your breach of these Terms or violation of any law
        or the rights of a third party.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">User Conduct</h2>
      <p className="mb-4">
        You agree to use our website solely for lawful purposes and will not
        engage in any activity that violates laws or infringes on the rights of
        others.
      </p>

      <h2 className="text-4xl font-semibold text-white mb-4">Complaints</h2>
      <p className="mb-4">
        Please email us at info@peryton.in for any complaints or comments. We
        will use a complaint handling procedure to resolve disputes when they
        arise.
      </p>
    </div>
  );
};

export default TermsAndConditions;
