import React from 'react';
import StyledHeading from '../components/styledHeading';

const AboutUs = () => {
  return (
    <div className="px-10 md:px-40 py-8 pb-16 bg-zinc-950 overflow-hidden ">
     <div className="mx-auto">
        <StyledHeading bg_text="About Us" fg_text="About Us" />
      </div>
      <p className="text-lg text-justify leading-relaxed">
        At Peryton Technologies, we are dedicated to revolutionizing the drone industry with our
        cutting-edge, high-performance drones designed to offer an engaging and intuitive flying experience. 
        Our diverse range of products, from racing drones built for speed and agility to industrial drones equipped 
        with advanced sensors and cameras, cater to the specific needs of both enthusiasts and professionals.
      </p>
      <p className="text-lg text-justify leading-relaxed mt-4">
        Driven by a passion for innovation, we prioritize pushing the boundaries of technology to deliver exceptional 
        performance and reliability, ensuring our users enjoy extraordinary flying experiences.
      </p>
      <p className="text-lg text-justify leading-relaxed mt-4">
        We specialize in assembling fully customized drones and FPV drones, providing our customers with tailored 
        solutions that meet their unique requirements. In addition to our comprehensive drone offerings, we sell 
        high-quality drone parts and components, enabling enthusiasts and professionals to build and maintain their 
        drones with ease.
      </p>
      <p className="text-lg text-justify leading-relaxed mt-4">
        With the technology to create mesmerizing drone light shows and our commitment to addressing industry 
        challenges, Peryton Technologies stands out with versatile solutions that transform the skies and unlock 
        new possibilities in drone technology.
      </p>
    </div>
  );
};

export default AboutUs;
